//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    blok: { type: Object, required: true },
  },
  computed: {
    hasMedia() {
      return this.blok?.media?.length;
    },
    animaStar() {
      return this.blok.animaStar
        ? {
            src: `/${this.blok.animaStar}-star.svg`,
            alt: `${this.blok.animaStar} star`,
          }
        : null;
    },
    decorationClass() {
      return this.blok.animaStar
        ? `decoration--${this.blok.animaStar}`
        : undefined;
    },
    cssVars() {
      return {
        '--section-margin-top': `${this.blok.marginTop}rem`,
      };
    },
  },
};
